<template>
  <div>
    <bread-crumb
      :crumbData="['商品详情']"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>
    <div class="box">
      <div class="img">
        <div class="img-slideshow">
          <div class="img-slideshow-title">轮播图</div>
          <div class="img-slideshow-box">
            <div
              class="img-slideshow-box-item"
              v-for="(item, index) in images"
              :key="index"
            >
              <i
                v-if="!item.image"
                class="el-icon-plus avatar-uploader-icon"
              ></i>
              <el-image
                v-else
                :src="'http://192.168.3.3' + item.image"
                class="avatar"
              />
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :http-request="(file) => uploadImg(file, index)"
              >
                <el-button size="small" type="primary" class="uploadBtu"
                  >选择图片</el-button
                >
              </el-upload>
            </div>
          </div>
        </div>
        <div class="img-video">
          <div class="video-title">视频</div>
          <div class="img-slideshow-box-item">
            <i v-if="!video" class="el-icon-plus avatar-uploader-icon"></i>
            <video v-else :src="'http://192.168.3.3' + video" class="avatar" />
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="uploadVideo"
            >
              <el-button size="small" type="primary" class="uploadBtu"
                >添加视频</el-button
              >
            </el-upload>
          </div>
        </div>
        <div class="img-slide">
          <div class="img-slideshow-title">分享图</div>
          <div class="img-slideshow-box-item">
            <i v-if="!image6" class="el-icon-plus avatar-uploader-icon"></i>
            <el-image
              v-else
              :src="'http://192.168.3.3' + image6"
              class="avatar"
            />
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="uploadImg6"
            >
              <el-button size="small" type="primary" class="uploadBtu"
                >添加分享图</el-button
              >
            </el-upload>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="product-title">产品介绍</div>
        <el-table :data="tableData" style="width: 100%" border>
          <el-table-column label="图片" width="180">
            <template slot-scope="scope">
              <el-image
                :src="'http://192.168.3.3' + scope.row.image"
                class="avatar"
                v-if="scope.row.image"
              />
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :http-request="(params) => uploadPic(params, scope.$index)"
              >
                <el-button size="small" type="primary" class="uploadBtu"
                  >选择图片</el-button
                >
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="描述">
            <template slot-scope="scope">
              <div style="padding: 10px 40px">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="scope.row.textarea"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="排序" width="140">
            <template slot-scope="scope">
              <el-button @click="upClick(scope.$index, scope.row)"
                >上移</el-button
              >
              <el-button @click="nextClick(scope.$index)">下移</el-button>
            </template>
          </el-table-column>
          <el-table-column width="50">
            <template slot-scope="scope">
              <i
                class="el-icon-delete"
                @click="deleteClick(scope.row)"
                style="cursor: pointer"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="addImage">添加图片</el-button>
      </div>
      <el-button type="primary" @click="submitClick">保存详情</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      images: [
        { image: "" },
        { image: "" },
        { image: "" },
        { image: "" },
        { image: "" },
      ],
      image6: "",
      video: "",
      tableData: [{ image: "", textarea: "" }],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    onClose() {
      this.$emit("close", 0);
    },
    async uploadImg(file, index) {
      try {
        const base64 = await this.getBase64(file.file);
        const image = await this.$http.post("uploads_images/up", {
          file: base64,
        });
        this.images[index].image = image.data.path;
      } catch (error) {
        console.log(error);
      }
    },
    async uploadImg6(file) {
      try {
        const base64 = await this.getBase64(file.file);
        const image = await this.$http.post("uploads_images/up", {
          file: base64,
        });
        this.image6 = image.data.path;
      } catch (error) {
        console.log(error);
      }
    },
    async uploadVideo(file) {
      console.log(file);
      this.$http
        .post("/uploads_images/upload", {
          file: file.file,
        })
        .then((res) => {
          console.log(res);
        });
    },
    async uploadPic(file, index) {
      try {
        const base64 = await this.getBase64(file.file);
        const image = await this.$http.post("uploads_images/up", {
          file: base64,
        });
        this.tableData[index].image = image.data.path;
      } catch (error) {
        console.log(error);
      }
    },
    addImage() {
      this.tableData.push({ image: "", textarea: "" });
    },
    submitClick() {
      console.log("提交");
      console.log(this.tableData);
    },
    upClick(index) {
      if (index == 0) {
        this.$message({
          type: "warning",
          message: "这已经是第一行了",
        });
        return;
      }
      const arr = this.tableData[index - 1];
      this.$set(this.tableData, index - 1, this.tableData[index]);
      this.$set(this.tableData, index, arr);
    },
    nextClick(index) {
      if (index == this.tableData.length - 1) {
        this.$message({
          type: "warning",
          message: "这已经是最后一行了",
        });
        return;
      }
      const arr = this.tableData[index];
      this.$set(this.tableData, index, this.tableData[index + 1]);
      this.$set(this.tableData, index + 1, arr);
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
  },
};
</script>
<style lang="less" scoped>
.avatar-uploader-icon {
  font-size: 38px;
  color: #010101;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.box {
  margin: 30px 20px;
  .img {
    display: flex;
    &-slideshow {
      &-title {
        font-size: 30px;
      }
      &-box {
        display: flex;
        &-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        }
      }
    }
    &-video {
      .video-title {
        font-size: 30px;
      }
    }
  }
  .avatar {
    max-width: 100px;
  }
  .product {
    margin-top: 20px;
    &-title {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
}
</style>
